* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: var(--fontFaceBasic);
}

html,
body,
#root {
    width: 100%;
    height: 100%; 
    scroll-behavior: smooth;
}

body {
    background: var(--darkGray);
}
